<template>
  <div>
    <b-card
      no-body
    >
      <base-actions-table
        :columns="fields"
        :records="records"
        :busy="isBusy"
        :is-processing="isProcessing"
        :module="module"
        @creating="handleCreating"
        @editing="handleEditing"
        @deleting="handleDeleting"
      >
        <template #cell(time)="data">
          {{ parseTime(data.item.time) }}
        </template>
        <template #cell(color)="data">
          <b-badge
            class="d-block"
            :style="`background-color: ${data.item.color} !important; width:24px; height:24px;`"
          >
            &nbsp;
          </b-badge>
        </template>
      </base-actions-table>
    </b-card>
    <b-modal
      id="patientrisk-modal"
      ref="patientrisk"
      title="Riesgo"
      hide-footer
    >
      <b-card-text>
        <risks-form
          ref="form"
          :record="selectedRecord"
          @save="save"
          @edit="edit"
        />
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
  import {
    BModal,
    BCardText,
    BCard,
    BBadge,
  } from 'bootstrap-vue'
  import CRUDMixin from '@/mixins/CRUDMixin'
  import PermissionMixin from '@/mixins/PermissionMixin'
  import RisksForm from './RisksForm'
  import BaseActionsTable from '@/components/base/BaseActionsTable'

  export default {
    name: 'Exams',
    inject: ['risksRepository'],
    components: {
      BCard,
      BBadge,
      BCardText,
      BModal,
      RisksForm,
      BaseActionsTable,
    },
    mixins: [CRUDMixin, PermissionMixin],
    data() {
      return {
        fields: [
          {
            key: 'name',
            label: 'Riesgos paciente',
          },
          {
            key: 'time',
            label: 'Tiempo',
          },
          {
            key: 'color',
            label: 'Color',
          },
          {
            key: 'actions',
            label: 'Acciones',
          },
        ],
        records: [],
        filter: null,
        filterOn: [],
        selectedRecord: null,
        repository: 'risksRepository',
        module: 'patientrisk',
        isBusy: false,
      }
    },

    async mounted() {
      const me = this
      await me.handleLoadData()
    },

    methods: {
      handleCreating() {
        this.selectedRecord = null
        this.$bvModal.show('patientrisk-modal')
      },

      handleEditing(record) {
        this.selectedRecord = record
        this.$bvModal.show('patientrisk-modal')
      },

      handleDeleting(record) {
        this.del(record.id)
      },

      parseTime(time) {
        const [hour, minutes] = time.split(':')
        let timeString = `${hour} H, ${minutes} M`
        if (hour === '00') {
          timeString = `${minutes} M`
        }

        if (minutes === '00') {
          timeString = `${hour} H`
        }

        return timeString
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
