<template>
  <div>
    <b-form @submit.prevent>
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Nombre riesgo*"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="formData.name"
              placeholder="Nombre de riesgo"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Color"
            label-for="color"
          >
            <b-form-input
              id="color"
              v-model="formData.color"
              type="color"
              placeholder="Color"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Tiempo de alerta"
          >
            <b-form-timepicker
              v-model="formData.time"
              locale="es"
              :hour12="false"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" />
        <b-col
          md="6"
          class="text-right"
        >
          <b-button
            type="button"
            variant="primary"
            :disabled="isBusy || $v.$invalid"
            @click="saveData"
          >
            <b-spinner
              v-if="isBusy"
              small
            />
            <v-icon
              v-else
              name="save"
            />
            Guardar
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
  import {
    BForm,
    BFormGroup,
    BButton,
    BCol,
    BFormInput,
    BRow,
    BSpinner,
    BFormTimepicker,
  } from 'bootstrap-vue'
  import { required } from 'vuelidate/lib/validators'
  import CRUDMixin from '@/mixins/CRUDMixin'

  export default {
    name: 'RiskForm',
    components: {
      BForm,
      BFormGroup,
      BButton,
      BCol,
      BFormInput,
      BRow,
      BSpinner,
      BFormTimepicker,
    },
    mixins: [CRUDMixin],
    props: {
      record: {
        type: Object,
        default: null,
      },
    },
    inject: ['risksRepository'],

    data() {
      return {
        formData: {
          name: '',
          color: '',
          time: '',
        },
        repository: 'risksRepository',
        isBusy: false,
      }
    },

    watch: {
      'formData.time'() {
        this.formData.time = this.formData.time.split(':').slice(0, 2).join(':')
      },
    },

    mounted() {
      const me = this
      if (me.record) {
        me.formData = { ...me.record }
      }
    },

    validations: {
      formData: {
        name: {
          required,
        },
        color: {
          required,
        },
        time: {
          required,
        },
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
